import { Link } from "gatsby"
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from "prop-types"
import * as styles from "./style.module.css"


import Layout from "../layout"
import HeadingBanner from "../headingbanner"
import VideoThumbnail from "../videothumbnail"
import VideoPlayer from "../videoplayer"

import btnback from "../../images/back-arrow.png"

const Playlist = ({ location, headingClassName, path, bannerimg, title, writeup, videolist }) => {
/*
		videoid: "",
		videotype: "",
		videotitle: "",
		videodescription: ""
*/

   const [urldata, setUrldata] = useState(location);
   const [videoid, setVideoid] = useState("");
   const [videotype, setVideotype] = useState("");
   const [videotitle, setVideotitle] = useState("");
   const [videodescription, setVideodescription] = useState("");

   const handleThumbnailClick = useCallback((videodata) => {
        setVideotype(videodata.videotype);
        setVideoid(videodata.videoid);
        setVideotitle(videodata.title);
        setVideodescription(videodata.writeup);
        if (window) {
            if (window.hasOwnProperty("location")) {
                window.location.hash = "#"+videodata.videoid;
            }
        }
    }, []);

   function handleVideoClose(){
        setVideotype("");
        setVideoid("");
        setVideotitle("");
        setVideodescription("");
        setUrldata({});
    }

    useEffect(() => {
       if (urldata) {
           if (urldata.hasOwnProperty("hash")) {
                if (urldata.hash.length > 1) {
                    var tmpvidid = urldata.hash.substring(1);
                    var idx = 0;
                    while (idx < videolist.length) {
                        if (videolist[idx].videoid === tmpvidid) {
                            handleThumbnailClick(videolist[idx]);
                            idx = videolist.length;
                        }
                        idx++;
                    }
                }
           }
       }
    }, [urldata, videolist, handleThumbnailClick])
  return (
    <Layout activePath={path} pageTitle={title} pageDescription={writeup} pageImage={"/images/pages/"+path+".jpg"}>
        <HeadingBanner className={styles.playlistheading+" "+headingClassName}>
            <img className={styles.playlistbanner} src={bannerimg} alt={""} />
            <div className={styles.playlistdetails}>
                <Link className={styles.playlistbackbtn} to="/">
                    <img src={btnback} alt={"Back"} />
                </Link>
            </div>
        </HeadingBanner>        
        <div className={styles.playlistmobiledetailsinfo}>
            <h2 className={"font-size-large font-style-sectiontitle"}>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: writeup.replace(/\n/g,"<br/><br/>") }} />
        </div>

        <div className={styles.playlistitemsholder}>
            <div className={styles.playlistitems}>
                {
                    videolist.map((videodata, videoindex)=> {
                        return (<>
                            { videodata.videoid !== "" ?
                                <VideoThumbnail
                                    cornertype="dark"
                                    className={styles.playlistitemsthumbnail}
                                    videodata={videodata}
                                    onclickhandler={handleThumbnailClick}
                                />
                            :
                                <div className={styles.playlistsectiontitle}>
                                    <hr className={"color-border-bottom-white"}/>
                                    <h3 className={"font-size-large font-style-sectiontitle"}>{videodata.title}</h3>
                                    {videodata.writeup!=="" &&
                                        <div dangerouslySetInnerHTML={{ __html: videodata.writeup.replace(/\n/g,"<br/><br/>").replace(/Back to the Edge 24th/g,"Back to the Edge 24<sup>th</sup>") }} />
                                    }
                                </div>
                            }
                        </>)
                    })
                }
            </div>
        </div>
        <VideoPlayer 
					className={""}
					videotype={videotype}
					videoid={videoid} 
					videotitle={videotitle}
					videodescription={videodescription}
					onclosehandler={handleVideoClose} />
    </Layout>
  )

}
Playlist.propTypes = {
    headingClassName: PropTypes.string
}

Playlist.defaultProps = {
    headingClassName:``
}

export default Playlist
